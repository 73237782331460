<template>
  <v-card
    class="mx-6 elevation-0 pb-6"
  >
    <div class="d-flex flex-column">
      <div
        v-if="!isSmartAdvisorSelected && recommendation.sold_percent_last_updated"
        class="body-2 grey--text text--darken-3 mx-6 mt-3 d-flex flex-column align-end"
      >
        <span>Recommendation created on <br> {{ formattedDate(recommendation.sold_percent_last_updated) }}</span>
      </div>

      <v-card-text
        v-if="isSmartAdvisorSelected"
        class="d-flex flex-center"
      >
        <v-divider
          class="mr-2 custom-divider"
          :class="{'show-recommendation' : showRecommendation}"
          vertical
        />
        <span
          v-if="showRecommendation"
          class="text-h5"
        >
          Move to
          <span class="text-color">
            {{ formattedRecommendation }}
          </span>
          sold on {{ selectedYearLongName }}
          <span class="text-color">
            {{ commodityDisplayName }}.
          </span>
        </span>
        <v-row v-else>
          <v-col class="text-h5">
            <span class="primary--text">
              Hold
            </span>
            {{ commodityDisplayName }}.
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text
        v-else
        class="d-flex flex-center"
      >
        <v-divider
          class="mr-2 custom-divider"
          :class="{'show-recommendation' : showRecommendation}"
          vertical
        />
        <span
          v-if="showRecommendation"
          class="text-h5"
        >
          Move to
          <span class="text-color">
            {{ formattedRecommendation }}
          </span>
          sold on {{ selectedYearLongName }}
          <span class="text-color">
            {{ commodityDisplayName }}.
          </span>
        </span>

        <v-row v-else>
          <v-col class="text-h5">
            <span>
              No sales recommendation for selected crop year,
            </span>
            <span class="primary--text">
              Hold
            </span>
            <span>
              {{ commodityDisplayName }}.
            </span>
          </v-col>
        </v-row>
      </v-card-text>

      <div
        v-if="!isSmartAdvisorSelected"
        class="d-flex justify-center"
      >
        <v-btn
          class="primary text-button-bold text-wrap"
          :href="priceMapLink"
        >
          <v-icon small>
            mdi-currency-usd
          </v-icon>
          FIND PRICES NEAR YOU
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { roundUpTo, formatUnitTemplateWithDelimiters } from '@/helpers/formatting';

export default {
  name: 'NewSalesRecommendations',

  props: {
    bestSaleData: {
      type: Object,
      default: () => {},
    },
    selectedYear: {
      type: Object,
      required: true,
    },

    selectedCommodity: {
      type: Object,
      required: true,
    },

    isSmartAdvisorSelected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('smartAdvisor', ['productionsSorted', 'soldPercentageByAADCommodity']),
    ...mapState('analysisDashboard', ['advancedAnalysisCommodities', 'priceMapLinks', 'salesRecommendations']),
    ...mapGetters('shared', ['unitByAbbreviation', 'cropYearById', 'currentCropYear', 'nextCropYear']),

    selectedYearLongName() {
      return this.selectedYear?.long_name;
    },

    commodityDisplayName() {
      return this.selectedCommodity?.display_name;
    },

    bestSaleDataForSelectedCommodity() {
      return this.bestSaleData[this.selectedCommodity?.name];
    },

    showRecommendation() {
      if (!this.isSmartAdvisorSelected) { return !!Object.keys(this.recommendation).length; }
      return this.recommendedPercentage > 0;
    },

    percentUnit() {
      return this.unitByAbbreviation('%');
    },

    recommendation() {
      const isSelectedCommodity = (recommendation) => recommendation.commodity_id === this.selectedCommodity?.id;
      const fromSelectedYear = (recommendation) => recommendation.crop_year_id === this.selectedYear?.id;
      return this.salesRecommendations.find((rec) => isSelectedCommodity(rec) && fromSelectedYear(rec)) || {};
    },

    recommendedPercentage() {
      if (!this.isSmartAdvisorSelected) { return 0; }

      const salePercent = this.bestSaleDataForSelectedCommodity?.salePercentage || 0;
      const sold = this.soldPercentageByAADCommodity[this.selectedCommodity?.name] || 0;
      const total = salePercent + sold;
      const value = roundUpTo(total, 1);

      if (value > 99) {
        return 100;
      }
      return roundUpTo(value, 1);
    },

    formattedRecommendation() {
      const numberOfDecimals = this.recommendedPercentage === 100 ? 0 : 1;
      if (this.isSmartAdvisorSelected) {
        return formatUnitTemplateWithDelimiters(this.percentUnit, this.recommendedPercentage, numberOfDecimals);
      }

      return formatUnitTemplateWithDelimiters(
        this.percentUnit,
        this.recommendation?.sold_percent ?? 0.0,
        numberOfDecimals,
      );
    },

    priceMapLink() {
      const url = new URL(
        '/bids/map',
        window.location.origin,
      );
      url.searchParams.set('crop', this.selectedCommodity.display_name);
      url.searchParams.set('commodity', this.selectedCommodity.name);
      const reference = this.priceMapLinks?.[this.selectedCommodity.id];
      if (reference) {
        url.searchParams.set('crop', reference.crop);
        url.searchParams.set('commodity', reference.commodity);
      }
      url.searchParams.set('sr', true);
      return url.toString();
    },
  },

  methods: {
    roundUpTo,
    formatUnitTemplateWithDelimiters,
    formattedDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
};

</script>

<style lang="scss" scoped>
@use "@/colors/colors";

.custom-divider {
  border-color: colors.$primary;
  border-width: 2px;
  height: 30px;
}

@media (max-width: 767px) {
  .show-recommendation {
    height: 60px;
  }
}

.text-color {
  color: colors.$positive;
}
</style>
