<template>
  <div class="chip-select">
    <div class="chip-select__input pa-3">
      <div class="ma-n3 pa-3">
        <label
          v-if="!value.length"
          class="v-label theme--light"
        >
          {{ label }}
        </label>
        <v-chip
          v-for="(item, index) in value"
          :key="index"
          :close="!mandatory || mandatory && internalValue.length > 1"
          class="ma-1"
          @click:close="remove(index)"
        >
          {{ getItemText(item) }}
        </v-chip>
      </div>
    </div>
    <div class="chip-select__chips py-3">
      <v-chip-group
        :value="internalValue"
        multiple
        column
        @change="handleChange"
      >
        <v-chip
          v-for="(item, index) in uniqueItems"
          v-show="getVisibility(index)"
          :key="index"
        >
          {{ getItemText(item) }}
        </v-chip>
        <div
          v-if="$isMobile"
          class="w--100 pt-1"
        >
          <slot
            name="activator"
            :click="openDialog"
          />
        </div>
      </v-chip-group>
    </div>
    <GDialog
      v-model="dialogOpen"
      :title="moreDialogTitle"
      :action-buttons="actionButtons"
      disable-fullscreen
      keep-form
      eager
    >
      <p v-if="moreDialogText">
        {{ moreDialogText }}
      </p>
      <v-list flat>
        <v-list-item-group
          :value="internalValue"
          multiple
          active-class=""
          @change="handleChange"
        >
          <v-list-item
            v-for="(item, index) in uniqueItems"
            :key="index"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ getItemText(item) }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </GDialog>
  </div>
</template>

<script>
import GDialog from '@/components/generic/GDialog.vue';

export default {
  name: 'ChipSelect',

  components: {
    GDialog,
  },

  props: {
    /**
     * Value that tracks selected items, works with v-model.
     */
    value: {
      type: Array,
      required: true,
    },

    /**
     * The set of items that can be selected.
     * Can be an array of objects, strings, or numbers.
     */
    items: {
      type: Array,
      default: () => [],
    },

    /**
     * When items is an array of objects, this will be used to get the "value" of the object.
     * This component has an implicit "return object" behaviour, so we always return the full
     * object to the parent, not this value. It's better to think of this as a unique ID.
     * The item value is used for tracking internal state and the deduplication step.
     */
    itemValue: {
      type: String,
      default: 'id',
    },

    /**
     * When items is an array of objects, this will be used to get the text displayed in the chip.
     */
    itemText: {
      type: String,
      default: 'name',
    },

    /**
     * The placeholder text to show in the chip box.
     */
    label: {
      type: String,
      default: '',
    },

    /**
     * The title of the "more items" dialog.
     */
    moreDialogTitle: {
      type: String,
      default: 'Select',
    },

    /**
     * The description/subtitle text of the "more items" dialog.
     */
    moreDialogText: {
      type: String,
      default: '',
    },

    mandatory: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogOpen: false,
      actionButtons: [
        {
          name: 'Done',
          side: 'right',
          type: 'primary',
          onClick: this.closeDialog,
        },
      ],
    };
  },

  computed: {
    /**
     * Filters duplicates from the items prop.
     * For objects, we check the item value, which must be unique.
     */
    uniqueItems() {
      const uniqueItemValues = new Map();

      this.items.forEach((item) => {
        const value = this.getItemValue(item);
        if (value !== null && !uniqueItemValues.has(value)) {
          uniqueItemValues.set(value, item);
        }
      });

      return Array.from(uniqueItemValues.values());
    },

    /**
     * An array of indices used by the VChipGroup.
     */
    internalValue() {
      return this.value.map((item) => this.uniqueItems
        .findIndex((i) => this.getItemValue(i) === this.getItemValue(item)));
    },
  },

  methods: {
    /**
     * Removes a selected item from the VChipGroup,
     * then communicates the change to the parent.
     */
    remove(index) {
      const value = [...this.internalValue];
      value.splice(index, 1);
      this.handleChange(value);
    },

    /**
     * Resets the selected items.
     */
    reset() {
      this.$emit('input', []);
    },

    /**
     * Emits an inpur event to update the parent's v-model.
     */
    handleChange(value) {
      this.$emit('input', value.map((index) => this.uniqueItems[index]));
    },

    /**
     * Gets the item value.
     */
    getItemValue(item) {
      if ((item ?? null) === null) return null;
      if (typeof item === 'string') return item;
      if (typeof item === 'object' && this.itemValue in item) return item[this.itemValue];
      return item;
    },

    /**
     * Gets the item display text.
     */
    getItemText(item) {
      if ((item ?? null) === null) return null;
      if (typeof item === 'string') return item;
      if (typeof item === 'object' && this.itemText in item) return item[this.itemText];
      return item.toString();
    },

    /**
     * Checks whether the item should be shown in the list of available chips.
     */
    getVisibility(index) {
      const maxNumberOfChips = 8;
      return !this.internalValue.includes(index)
        && !(this.$isMobile && index > maxNumberOfChips - 1);
    },

    /**
     * Opens the "more items" dialog.
     */
    openDialog() {
      this.dialogOpen = true;
    },

    /**
     * Closes the "more items" dialog.
     */
    closeDialog() {
      this.dialogOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import "vuetify/src/components/VTextField/variables";

.chip-select {
  &__input {
    border-collapse: collapse;
    border-color: currentColor;
    border-radius: $text-field-border-radius;
    border-style: solid;
    border-width: $text-field-outlined-fieldset-border-width;
    min-height: 106px;
  }

  &__chips {
    min-height: 72px;

    .v-slide-group__content {
      min-height: 48px;
    }
  }
}
</style>
