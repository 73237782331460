<template>
  <v-card
    v-if="showWidget"
    class="mb-6 mx-0 px-5 pt-5"
    :class="{'pb-16': loadingBase}"
  >
    <SalesRecommendationCommoditySettings
      v-if="!loadingBase && !isSmartAdvisorSelected"
      :value="commoditySettingsOpen"
      :crop-year="currentCropYear"
      @input="commoditySettingsOpen = false"
      @change="updateSelectableCommodities"
    />

    <div
      class="d-flex justify-space-between"
      :class="$isMobile ? 'flex-column' : 'flex-row'"
    >
      <div
        class="d-flex flex-row justify-space-between align-content-center"
      >
        <span
          class="primary-grey--text text-h6 font-weight-bold"
        >
          {{ title }}
        </span>

        <v-btn
          v-if="!isCommercial && !isSmartAdvisorSelected && $isMobile"
          class="pb-1"
          icon
          :disabled="loadingBase"
          @click="openCommoditySettings"
        >
          <v-icon
            medium
          >
            mdi-cog
          </v-icon>
        </v-btn>
      </div>

      <div
        class="d-flex flex-row"
        :class="$isMobile ? 'justify-start ml-n3' : 'justify-end'"
      >
        <span style="max-width: 70%">
          <v-select
            v-model="recommendationPreference"
            :items="recommendationPreferences"
            class="text-body-2 font-weight-medium primary-color"
            :disabled="loadingBase || loadingRecommendations"
            solo
            flat
            dense
            single-line
            hide-details
            @change="onPreferenceSelect"
          />
        </span>

        <v-btn
          v-if="!isCommercial && !isSmartAdvisorSelected && !$isMobile"
          icon
          :disabled="loadingBase"
          @click="openCommoditySettings"
        >
          <v-icon
            medium
          >
            mdi-cog
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-row
      class="px-0 mt-2"
      :class="$isMobile ? 'ml-n5' : 'mx-n7'"
    >
      <v-col
        :cols="$isMobile ? '8' : '3'"
        class="py-0 px-0 mx-0"
        no-gutters
      >
        <v-select
          v-model="selectedYear"
          :disabled="loadingBase"
          :items="selectableYears"
          class="font-weight-bold"
          :class="$isMobile ? 'ml-2' : 'ml-4'"
          flat
          solo
          item-text="long_name"
          item-value="id"
          return-object
        />
      </v-col>
    </v-row>
    <div class="mb-2 mt-0 justify-end">
      <v-chip-group
        v-if="!(loadingRecommendations || loadingBase)"
        v-model="selectedCommodity"
        mandatory
        column
        active-class="primary"
      >
        <v-chip
          v-for="item,index in selectableCommodities"
          :key="index"
          :value="item"
        >
          {{ item.display_name }}
        </v-chip>
      </v-chip-group>
    </div>
    <template
      v-if="loadingRecommendations === true || loadingBase === true"
    >
      <div class="grey--text text-center py-16">
        Loading...
      </div>
    </template>
    <sales-recommendations-c-t-a v-else-if="showCTA" />
    <div
      v-else
      class="mx-n10"
    >
      <NewSalesRecommendations
        :selected-year="selectedYear"
        :selected-commodity="selectedCommodity"
        :is-smart-advisor-selected="isSmartAdvisorSelected"
        :best-sale-data="cachedBestSaleData[selectedYear.id]"
      />
    </div>
  </v-card>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import SalesRecommendationsCTA from '../farm_profile/SalesRecommendationsCTA.vue';
import NewSalesRecommendations from './NewSalesRecommendations.vue';
import SalesRecommendationCommoditySettings from './SalesRecommendationCommoditySettings.vue';

export default {
  name: 'NewSalesRecommendationsWidget',

  components: {
    SalesRecommendationsCTA,
    SalesRecommendationCommoditySettings,
    NewSalesRecommendations,
  },

  data() {
    return {
      title: 'Sales recommendations',
      loadingBase: true,
      loadingRecommendations: false,
      cropData: [],
      recommendationPreference: 'analyst',
      recommendationPreferences: [{
        text: 'BY GRAINFOX ANALYST',
        value: 'analyst',
      },
      {
        text: 'BY SMART ADVISOR',
        value: 'smart-advisor',
      }],
      selectedCommodity: {},
      selectedCropYearInternal: null,
      commoditySettingsOpen: false,
      selectableCommodities: [],

      cachedBestSaleData: {},
      cachedSmartAdvisorCommodities: {},
    };
  },

  watch: {
    selectedYear: {
      async handler() {
        if (!this.isSmartAdvisorSelected || !this.selectedYear) { return; }

        if (!this.smartAdvisorDataCached) {
          await this.loadSmartAdvisorCropYearData();
        }
        this.selectableCommodities = this.cachedSmartAdvisorCommodities[this.selectedYear.id];
      },
    },
    isSmartAdvisorSelected: {
      async handler(value) {
        if (!value || !this.selectedYear) { return; }

        if (!this.smartAdvisorDataCached) {
          await this.loadSmartAdvisorCropYearData();
        }
        this.selectableCommodities = this.cachedSmartAdvisorCommodities[this.selectedYear.id];
      },
    },
  },

  computed: {
    ...mapState('analysisDashboard', ['advancedAnalysisCommodities', 'salesRecommendations']),
    ...mapGetters('analysisDashboard', ['GFToAADCommodityMapping']),
    ...mapGetters('permissions', ['checkPermission']),
    ...mapGetters('subscription', ['isDeputter', 'isSyngenta', 'isAmerican']),
    ...mapGetters('chargebee', ['isCommercialBasic', 'isCommercial']),
    ...mapGetters('smartAdvisor', ['commoditiesAvailableForOnboarding', 'productionsSorted', 'bestSaleData']),
    ...mapState('farmProfile/salesRecommendations', ['farmProfileCommoditiesList']),
    ...mapState('shared', ['cropYears']),
    ...mapGetters('shared', ['currentCropYear', 'nextCropYear']),

    years() {
      return this.cropYears.slice(0, 2);
    },

    selectableYears() {
      if (this.loadingBase) { return this.years; }

      if (this.isSmartAdvisorSelected) {
        const nextCropYearStartDate = new Date(this.nextCropYear.start);
        const nextCropYearStartYear = nextCropYearStartDate.getFullYear();

        const availabilityDate = new Date(nextCropYearStartYear, 1, 1);
        const currentDate = new Date();

        return this.years.map((year, index) => ({
          ...year,
          disabled: index === 0 && currentDate < availabilityDate,
        }));
      }

      if (!Array.isArray(this.selectableCommodities)) { return []; }
      return this.years.map((year) => ({
        ...year,
        disabled: !this.salesRecommendations
          .some((rec) => rec.crop_year_id === year.id && this.selectableCommodities
            .some((commodity) => rec.commodity_id === commodity.id)),
      }));
    },

    selectedYear: {
      get() {
        if (this.selectedCropYearInternal) return this.selectedCropYearInternal;
        return this.currentCropYear;
      },
      set(value) {
        this.selectedCropYearInternal = value;
      },
    },

    showCTA() {
      return !this.checkPermission('sales_recommendation', 'read') || this.isCommercialBasic;
    },

    showWidget() {
      return !(this.isAmerican || this.isDeputter || this.isSyngenta);
    },

    isSmartAdvisorSelected() {
      return this.recommendationPreference === 'smart-advisor';
    },

    smartAdvisorCommodities() {
      return Object.values(this.productionsSorted)
        .filter((production) => this.commoditiesAvailableForOnboarding[production.commodity])
        .map((production) => this.commoditiesAvailableForOnboarding[production.commodity]);
    },

    combinedCommoditiesString() {
      return Object.values(this.smartAdvisorCommodities).map((x) => x.name.replace(/ /g, '_')).join(' ');
    },

    selectedBestSaleData() {
      return this.cachedBestSaleData[this.selectedYear.id];
    },

    selectedSmartAdvisorCommodities() {
      return this.cachedSmartAdvisorCommodities[this.selectedYear.id];
    },

    smartAdvisorDataCached() {
      const bestSaleDataCached = !!this.selectedBestSaleData;
      const productionsCached = !!this.selectedSmartAdvisorCommodities;

      return bestSaleDataCached && productionsCached;
    },
  },

  async mounted() {
    this.loadingBase = true;
    this.recommendationPreference = localStorage.getItem('dashboardRecommendationPreference') || 'analyst';
    this.setSalesRecommendationsTab(!this.showWidget);

    // This should be set to 'next' when the next crop year is selected and
    // next crop year's recommendations are available
    this.setActiveContextKey('current');

    const [_, data] = await Promise.all([
      this.getStaticData(),
      API.readCommoditiesAndProvinces(),
    ]);
    await this.loadSmartAdvisorCropYearData();

    this.cropData = data.commodities;
    this.loadingBase = false;

    if (this.recommendationPreference === 'smart-advisor') {
      this.selectableCommodities = this.smartAdvisorCommodities;
    }
  },

  methods: {
    ...mapActions('shared', ['fetchAllCropYears', 'fetchAllUnits']),
    ...mapActions('analysisDashboard', [
      'fetchAllSalesRecommendations',
      'fetchAnalysisDashboardCommodities',
      'fetchPriceMapLinks',
    ]),
    ...mapActions('dashboard', ['setSalesRecommendationsTab']),
    ...mapActions('smartAdvisor', [
      'fetchAADCommodities',
      'getBestSaleData',
      'getSupportedParameters',
      'getSmartAdvisorProductions',
      'getPersonalizationDataByCropYearId']),
    ...mapActions('farmProfile/salesRecommendations', ['getFarmprofileCommodities']),
    ...mapMutations('smartAdvisor', ['setActiveContextKey']),

    async getStaticData() {
      await Promise.all([
        this.fetchAllCropYears(),
        this.fetchAllUnits(),
        this.fetchAnalysisDashboardCommodities(),
        this.fetchAllSalesRecommendations(),
        this.fetchPriceMapLinks(),
        this.getFarmprofileCommodities(),
      ]);
    },

    openCommoditySettings() {
      this.commoditySettingsOpen = true;
    },

    onPreferenceSelect(value) {
      if (value === 'smart-advisor') {
        this.selectableCommodities = this.smartAdvisorCommodities;
      }
      localStorage.setItem('dashboardRecommendationPreference', value);
    },

    updateSelectableCommodities(commodities) {
      this.selectableCommodities = commodities;
    },

    async loadSmartAdvisorCropYearData() {
      this.loadingRecommendations = true;
      this.setActiveContextKey(this.selectedYear?.id === this.currentCropYear?.id ? 'current' : 'next');
      await this.fetchAADCommodities();
      await this.getSmartAdvisorProductions();
      await this.getSupportedParameters();

      try {
        await Promise.all([
          this.getPersonalizationDataByCropYearId(this.selectedYear?.id),
        ]);
        await this.getBestSaleData({ commodities: this.combinedCommoditiesString });
      } catch (e) {
        this.$snackbar.error(e);
      }

      this.cachedBestSaleData[this.selectedYear.id] = { ...this.bestSaleData };
      this.cachedSmartAdvisorCommodities[this.selectedYear.id] = { ...this.smartAdvisorCommodities };
      this.loadingRecommendations = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/colors/colors";

::v-deep .v-text-field__details .v-select__selections input {
  display: none !important;
}

.primary-color::v-deep .v-select__selections {
  color: colors.$primary !important;
}
</style>
