<template>
  <GDialog
    :value="value"
    title="Commodity Settings"
    @input="$emit('input', false)"
  >
    <ChipSelect
      v-model="selectedCommodities"
      :items="fullCommodityList"
      item-text="display_name"
      item-value="id"
      mandatory
      label="Select commodities that you would like to see sale recommendations for."
      @input="storeFilter"
    />
  </GDialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import GDialog from '@/components/generic/GDialog.vue';
import ChipSelect from '@/components/generic/ChipSelect.vue';

export default {
  name: 'SalesRecommendationCommoditySettings',

  components: {
    GDialog,
    ChipSelect,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    cropYear: {
      type: Object,
      required: true,
    },
  },

  watch: {
    selectedCommodities(value) {
      this.$emit('change', value);
    },
  },

  mounted() {
    let storedFilter = localStorage.getItem('dashboardRecommendationCommodityFilterStore');

    try {
      storedFilter = JSON.parse(storedFilter);
    } finally {
      this.selectedCommodities = storedFilter || this.fullCommodityList;
    }
  },

  data() {
    return {
      selectedCommodities: [],
    };
  },

  computed: {
    ...mapState('analysisDashboard', ['advancedAnalysisCommodities', 'salesRecommendations']),

    fullCommodityList() {
      return Array.from(this.AADCommoditiesWithSalesRecommendations)
        .map((AADCommodityID) => this.advancedAnalysisCommodities[AADCommodityID]);
    },

    AADCommoditiesWithSalesRecommendations() {
      const relevantRecommendations = this.salesRecommendations
        .filter(
          (recommendation) => recommendation.crop_year_id === this.cropYear.id && recommendation.sold_percent > 0,
        );

      return relevantRecommendations.reduce((aadCommoditySet, recommendation) => {
        aadCommoditySet.add(recommendation.commodity_id);

        return aadCommoditySet;
      }, new Set());
    },
  },

  methods: {
    hasAnalystRecommendation(AADCommodity) {
      return this.AADCommoditiesWithSalesRecommendations.has(AADCommodity.id);
    },
    storeFilter(value) {
      localStorage.setItem('dashboardRecommendationCommodityFilterStore', JSON.stringify(value));
    },
  },
};
</script>
